import { PlusOutlined } from "@ant-design/icons";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { LicenseToggleButton } from "components/dev/LicenseToggleButton";
import { handleDealFormNavigate } from "components/form/standard/utils/navigation";
import useAuth from "hooks/useAuth";
import { checkLicense } from "hooks/useCheckLicense";
import HeaderNotification from "layout/MainLayout/Header/HeaderContent/HeaderNotification";
import { getUserFullName, showDevLicenseToggle } from "pages/deal/utils/deal";
import { startEditingRecord } from "store/reducers/record";
import { Product } from "types/license";
import { FormIdentifier } from "types/record";

import MobileSection from "./MobileSection";
import Profile from "./Profile";
import Search from "./Search";

// ==============================|| HEADER - CONTENT ||============================== //

function AddRecordButton() {
  const { user } = useAuth();

  const isProspects = checkLicense(user, [Product.prospects]);
  const isDVPro = checkLicense(user, [Product.deal_view_pro]);
  const navigate = useNavigate();

  if (isProspects) {
    return (
      <Button
        size={"medium"}
        variant="contained"
        startIcon={<PlusOutlined />}
        onClick={() =>
          startEditingRecord(undefined, FormIdentifier.QuickAddWizardForm)
        }
      >
        Add
      </Button>
    );
  } else if (isDVPro) {
    return (
      <Button
        size={"medium"}
        variant="contained"
        startIcon={<PlusOutlined />}
        onClick={() => {
          handleDealFormNavigate({}, navigate);
        }}
      >
        Add Deal
      </Button>
    );
  } else {
    return <></>;
  }
}

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { user } = useAuth();
  const isImpersonation = user?.impersonation;

  return (
    <>
      {!matchesXs && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={10}
          sx={{ width: "100%" }}
        >
          {false ? <Search /> : <Box />}
          {showDevLicenseToggle(user?.user_id) && (
            <Box justifyContent="center" alignItems="center" sx={{ mx: 1 }}>
              <LicenseToggleButton />
            </Box>
          )}
          {!!isImpersonation && (
            <Typography color={"white"} variant={"h1"}>
              {getUserFullName(user)}
            </Typography>
          )}
          <Stack direction={"row"} spacing={2} alignItems="center">
            <AddRecordButton />
            <HeaderNotification />
            <Profile />
          </Stack>
        </Stack>
      )}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
