import {
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  FeedOutlined,
  PersonOutlined,
  PlaceOutlined,
  SearchOutlined,
  TagOutlined,
  TitleOutlined,
} from "@mui/icons-material";
import * as Yup from "yup";

import { CheckboxField } from "components/form/CheckboxField";
import { DatePicker } from "components/form/DatePicker";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldPercent } from "components/form/TextFieldPercent";
import {
  TextFieldString,
  TextFieldStringMultiline,
} from "components/form/TextFieldString";
import {
  TextFieldWholeNumberUnformatted,
  TextFieldTenDecimal,
  TextFieldWholeNumber,
} from "components/form/TextFieldWholeNumber";
import { PropertyFormAddress } from "components/form_v2/property/PropertyFormAddress";
import { PropertyLotSizeField } from "components/form_v2/property/PropertyLotSizeField";
import { PropertySizeField } from "components/form_v2/property/PropertySizeField";
import { ContactLookupField } from "pages/deal/components/LookupField";
import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import {
  dateSubmitHandler,
  parseContactAsSimpleOption,
  parseDealOptionAsString,
  simpleOptionSubmitHandler,
  submitCurrency,
  submitTwoDecimal,
} from "pages/deal/utils/deal_form";
import { ReadOnlyAddress } from "pages/settings/team/sections/dynamic_form/ReadOnlyAddress";
import { ReadOnlyLotSize } from "pages/settings/team/sections/dynamic_form/ReadOnlyLotSize";
import { ReadOnlySize } from "pages/settings/team/sections/dynamic_form/ReadOnlySize";
import {
  ReadOnlyDatePickerField,
  ReadOnlyLookupField,
  ReadOnlyTextFieldCurrency,
  ReadOnlyTextFieldString,
  ReadOnlyTextFieldStringMultiline,
} from "pages/settings/team/sections/dynamic_form/ReadOnlyTextFieldString";
import { VisibilityLevelEnum } from "types/api/deal/dynamic_form/form_field";
import { FormSection } from "types/api/deal/dynamic_form/form_layout";
import { SizeType } from "types/deal";
import { PropertyObjectMetadataV3 } from "types/standardFormV3";

// string
// string (multiline)
// address
// datepicker
// currency
// autocomplete
// contactlookup (autocomplete?)

export const defaultPropertyFormFields: {
  [key: string]: {
    icon: any;
    canEditLayout?: boolean;
    canDelete?: boolean;
  };
} = {
  _address_search: {
    icon: PlaceOutlined,
    canEditLayout: false,
  }, //  address_line_1  address_line_2 address_city  address_state  address_postal_code address_country  address_latitude  address_longitude
  asset_type: {
    icon: SearchOutlined,
  },
  classification: {
    icon: SearchOutlined,
  },
  last_sold_date: {
    icon: CalendarTodayOutlined,
  },
  last_sold_price: {
    icon: AttachMoneyOutlined,
  },
  lot_size: {
    icon: TagOutlined,
  }, // lot_size and lot_size_type
  notes_plaintext: {
    icon: FeedOutlined,
  },
  name: {
    icon: TitleOutlined,
    canDelete: false,
  },
  property_owner: {
    icon: PersonOutlined,
  },
  size: {
    icon: TagOutlined,
  }, // size and size_type
  submarket: {
    icon: SearchOutlined,
  },
  year_built: {
    icon: TagOutlined,
  },
  zoning: {
    icon: SearchOutlined,
  },
};

export const readOnlyComponentMap = {
  TextFieldString: ReadOnlyTextFieldString,
  TextFieldStringMultiline: ReadOnlyTextFieldStringMultiline,
  DatePicker: ReadOnlyDatePickerField,
  TextFieldCurrency: ReadOnlyTextFieldCurrency,
  TextFieldWholeNumber: ReadOnlyTextFieldString,
  TextFieldWholeNumberUnformatted: ReadOnlyTextFieldString,
  // Special
  ContactLookupField: ReadOnlyLookupField,
  DealOptionAutocompleteNew: ReadOnlyLookupField,
  PropertyFormAddress: ReadOnlyAddress,
  PropertyLotSizeField: ReadOnlyLotSize,
  PropertySizeField: ReadOnlySize,
};

export const componentMap = {
  TextFieldString,
  TextFieldStringMultiline,
  DatePicker,
  TextFieldCurrency,
  TextFieldDecimalNumber: TextFieldTenDecimal,
  TextFieldPercent,
  TextFieldWholeNumber,
  TextFieldWholeNumberUnformatted,
  ContactLookupField,
  DealOptionAutocompleteNew,
  PropertyFormAddress,
  PropertyLotSizeField,
  PropertySizeField,
  CheckboxField,
};

export const PropertyBaseMetadata: PropertyObjectMetadataV3 = {
  name: {
    fieldName: "name",
    displayName: "Property Name",
    _schema: Yup.string().required("Property Name is required."),
    component: "TextFieldString",
    initialValue: "",
  },
  notes_plaintext: {
    fieldName: "notes_plaintext",
    displayName: "Notes",
    initialValue: "",
    _schema: Yup.string()
      .max(25000, "Notes must be less than 25,000 characters.")
      .nullable(),
    component: "TextFieldStringMultiline",
  },
  _address_search: {
    fieldName: "_address_search",
    displayName: "Address",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: "PropertyFormAddress",
    virtual: true,
  },
  address_city: {
    fieldName: "address_city",
    displayName: "City",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_country: {
    fieldName: "address_country",
    displayName: "Country",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_latitude: {
    fieldName: "address_latitude",
    displayName: "Latitude",
    initialValue: null,
    _schema: Yup.number().nullable(),
  },
  address_line_1: {
    fieldName: "address_line_1",
    displayName: "Line 1",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_line_2: {
    fieldName: "address_line_2",
    displayName: "Line 2",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_longitude: {
    fieldName: "address_longitude",
    displayName: "Longitude",
    initialValue: null,
    _schema: Yup.number().nullable(),
  },
  address_postal_code: {
    fieldName: "address_postal_code",
    displayName: "Zip",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_state: {
    fieldName: "address_state",
    displayName: "State",
    initialValue: null,
    _schema: Yup.string().max(2).nullable(),
  },
  last_sold_date: {
    fieldName: "last_sold_date",
    displayName: "Last Sold Date",
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Last Sold Date must be a date.`),
    component: "DatePicker",
    submitHandler: dateSubmitHandler,
  },
  last_sold_price: {
    fieldName: "last_sold_price",
    displayName: "Last Sold Price",
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Last Sold Price must be positive.")
      .nullable()
      .typeError(`A value must be entered for Last Sold Price.`),
    component: "TextFieldCurrency",
    submitHandler: submitCurrency,
  },
  asset_type: {
    fieldName: "asset_type",
    displayName: "Asset Type",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "ReadOnlyTextFieldString",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "asset_type_name",
  },
  classification: {
    fieldName: "classification",
    displayName: "Classification",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "DealOptionAutocompleteNew",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "classification_name",
  },
  submarket: {
    fieldName: "submarket",
    displayName: "Submarket",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "ReadOnlyTextFieldString",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "submarket_name",
  },
  zoning: {
    fieldName: "zoning",
    displayName: "Zoning",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "DealOptionAutocompleteNew",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "zoning_name",
  },
  year_built: {
    fieldName: "year_built",
    displayName: "Year Built",
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Year Built must be positive.")
      .nullable()
      .typeError(`Year Built must be a number.`),
    component: "TextFieldWholeNumberUnformatted",
  },
  property_owner: {
    fieldName: "property_owner",
    displayName: "Property Owner",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: "ContactLookupField",
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    submitFieldName: "property_owner_id",
  },
  size: {
    fieldName: "size",
    displayName: "Size",
    initialValue: null,
    _schema: Yup.number().nullable().min(0, "Size must be positive."),
    component: "PropertySizeField",
    submitHandler: submitTwoDecimal,
  },
  size_type: {
    fieldName: "size_type",
    displayName: "",
    initialValue: SizeType.square_feet,
    _schema: Yup.string().nullable(),
  },
  lot_size: {
    fieldName: "lot_size",
    displayName: "Lot Size",
    initialValue: null,
    _schema: Yup.number().nullable().min(0, "Lot Size must be positive."),
    component: "PropertyLotSizeField",
    submitHandler: submitTwoDecimal,
  },
  lot_size_type: {
    fieldName: "lot_size_type",
    displayName: "",
    initialValue: SizeType.square_feet,
    _schema: Yup.string().nullable(),
  },
};

export const getDefaultPropertyFormValues: () => {
  name: string;
  layout: FormSection[];
} = () => ({
  name: "Property Form",
  layout: [
    {
      name: "Property Details",
      content: [
        { column_width: 6, field_type: "default", field_name: "name" },
        {
          column_width: 6,
          field_type: "default",
          field_name: "property_owner",
        },
        {
          column_width: 12,
          field_type: "default",
          field_name: "_address_search",
        },
      ],
      visible: VisibilityLevelEnum.always,
      visible_condition: null, // set to null by default because the API returns null and not undefined
    },
    {
      name: "Additional Details",
      content: [
        { column_width: 6, field_type: "default", field_name: "asset_type" },
        { column_width: 6, field_type: "default", field_name: "submarket" },
        { column_width: 6, field_type: "default", field_name: "size" },
        { column_width: 6, field_type: "default", field_name: "lot_size" },
      ],
      visible: VisibilityLevelEnum.always,
      visible_condition: null, // set to null by default because the API returns null and not undefined
    },
    {
      name: "Notes",
      content: [
        {
          column_width: 12,
          field_type: "default",
          field_name: "notes_plaintext",
        },
      ],
      visible: VisibilityLevelEnum.always,
      visible_condition: null, // set to null by default because the API returns null and not undefined
    },
  ],
});
