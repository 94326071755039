import {
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  PercentOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";

interface ReadOnlyTextFieldStringProps {
  label: string;
  placeholder?: string;
  multiline?: boolean;
  EndAdornment?: any;
  StartAdornment?: any;
}

const REQUIRED_FIELD_LABELS = ["Property Name"];

export function ReadOnlyTextFieldString({
  label,
  placeholder,
  multiline,
  EndAdornment,
  StartAdornment,
}: ReadOnlyTextFieldStringProps) {
  const theme = useTheme();
  return (
    <Stack spacing="6px" style={{ width: "100%" }}>
      <Typography
        sx={{
          fontSize: "12px",
          lineHeight: "18px",
          minHeight: "18px", // for when we have an empty label
          fontWeight: 500,
        }}
      >
        {label}{" "}
        {REQUIRED_FIELD_LABELS.includes(label) ? (
          <span
            style={{
              color: theme.palette.error.main,
              fontSize: "1.25rem",
              lineHeight: "18px",
              fontWeight: 500,
              display: "inline",
            }}
          >
            *
          </span>
        ) : null}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: multiline ? "120px" : "44px",
          backgroundColor: "white",
          border: `1px solid ${theme.palette.grey[900]}`,
          borderRadius: "8px",
          padding: "11px 16px", // 12px but the border takes 1px
        }}
      >
        {StartAdornment ? (
          <StartAdornment
            sx={{ fontSize: "20px", color: theme.palette.grey[500] }}
          />
        ) : null}
        <Typography color={theme.palette.grey[500]} sx={{ flex: 1 }}>
          {placeholder || label}
        </Typography>
        {EndAdornment ? (
          <EndAdornment
            sx={{ fontSize: "20px", color: theme.palette.grey[500] }}
          />
        ) : null}
      </div>
    </Stack>
  );
}

export const ReadOnlyTextFieldStringMultiline = (
  props: ReadOnlyTextFieldStringProps
) => {
  return <ReadOnlyTextFieldString {...props} multiline={true} />;
};

export const ReadOnlyTextFieldCurrency = (
  props: ReadOnlyTextFieldStringProps
) => {
  return (
    <ReadOnlyTextFieldString
      {...props}
      placeholder="0.00"
      StartAdornment={AttachMoneyOutlined}
    />
  );
};

export const ReadOnlyTextFieldPercentage = (
  props: ReadOnlyTextFieldStringProps
) => {
  return (
    <ReadOnlyTextFieldString
      {...props}
      placeholder="0"
      EndAdornment={PercentOutlined}
    />
  );
};

export const ReadOnlyLookupField = (props: ReadOnlyTextFieldStringProps) => {
  return <ReadOnlyTextFieldString {...props} EndAdornment={SearchOutlined} />;
};

export const ReadOnlyTextFieldDecimal = (
  props: ReadOnlyTextFieldStringProps
) => {
  return <ReadOnlyTextFieldString {...props} placeholder="0.0" />;
};

export const ReadOnlyDatePickerField = (
  props: ReadOnlyTextFieldStringProps
) => {
  return (
    <ReadOnlyTextFieldString
      {...props}
      placeholder={new Date().toLocaleDateString()}
      EndAdornment={CalendarTodayOutlined}
    />
  );
};

export function ReadOnlyWholeNumberField(props: ReadOnlyTextFieldStringProps) {
  return <ReadOnlyTextFieldString {...props} placeholder="1,000" />;
}

export function ReadOnlyWholeNumberUnformattedField(
  props: ReadOnlyTextFieldStringProps
) {
  return <ReadOnlyTextFieldString {...props} placeholder="1000" />;
}
