// Mock imports for external types

// Enum translations
import {
  FormCondition,
  SimpleFormCondition,
} from "types/api/deal/dynamic_form/form_eval";
import { BaseTable } from "types/api/deal/schemaAddOns";

export enum CustomFormEntityEnum {
  property = "property",
  invoice = "invoice",
  voucher = "voucher",
}

export enum CustomDataTypeEnum {
  text = "text",
  multiline_text = "multiline_text",
  currency = "currency",
  date = "date",
  whole_number = "whole_number",
  whole_number_unformatted = "whole_number_unformatted",
  decimal_number = "decimal_number",
  percent_number = "percent_number",
  boolean = "boolean",
}

export enum RequirementLevelEnum {
  never = "never",
  sometimes = "sometimes",
  always = "always",
}

export enum VisibilityLevelEnum {
  sometimes = "sometimes",
  always = "always",
}

// SQL Model translated to TypeScript interfaces
export interface FormFieldBase extends BaseTable {
  organization_id: number;
  entity_type: CustomFormEntityEnum;

  name: string;
  display_name: string;
  description: string;
  data_type: CustomDataTypeEnum;

  required: RequirementLevelEnum;
  required_condition?: SimpleFormCondition;

  visible: VisibilityLevelEnum;
  visible_condition?: FormCondition;
}

export interface FormField extends FormFieldBase {
  __tablename__: "form_field";
}

export interface FormFieldRead extends FormFieldBase {}

export interface CustomFormFieldUpdate {
  display_name: string;
  description: string;
  data_type: CustomDataTypeEnum;

  required: RequirementLevelEnum;
  required_condition?: SimpleFormCondition;

  visible: VisibilityLevelEnum;
  visible_condition?: FormCondition;
}

export interface CustomFormFieldCreate extends CustomFormFieldUpdate {
  name: string;
  entity_type: CustomFormEntityEnum;
  data_type: CustomDataTypeEnum;
}
