import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik } from "formik";
import { get, reduce } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { DatePicker } from "components/form/DatePicker";
import { FormDialogActions } from "components/form/standard/FormDialogActions";
import { formatDateForDisplay } from "components/form/standard/utils/formatting";
import {
  deriveYupSchemaFromMetadataV3,
  getInitialValuesV3,
  getOnSubmitV3,
} from "components/form/standard/utils/metadataV3";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import {
  TextFieldString,
  TextFieldStringMultiline,
} from "components/form/TextFieldString";
import {
  TextFieldTwoDecimal,
  TextFieldWholeNumber,
  TextFieldWholeNumberUnformatted,
} from "components/form/TextFieldWholeNumber";
import { CheckboxField } from "components/form_v2/CheckboxField";
import { PercentField } from "components/form_v2/PercentField";
import MainCard from "components/MainCard";
import { InvoiceBaseMetadata } from "constants/objectMetadata/invoiceMetadata";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupRequiredFieldsProvider } from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { dateSubmitHandler, submitCurrency } from "pages/deal/utils/deal_form";
import { formatCurrency } from "pages/deal/utils/reporting";
import { useFormFieldDictQuery } from "queries/useFormField";
import { useFormLayoutQuery } from "queries/useFormLayout";
import { getInvoiceQueryKey, useInvoiceQuery } from "queries/useInvoice";
import {
  CustomDataTypeEnum,
  CustomFormEntityEnum,
  FormFieldRead,
} from "types/api/deal/dynamic_form/form_field";
import { FormLayoutRead } from "types/api/deal/dynamic_form/form_layout";
import { Invoice, InvoiceRead } from "types/api/deal/invoice";
import {
  InvoiceFieldMetadata,
  InvoiceObjectMetadataV3,
  isFieldMetadataV3,
} from "types/standardFormV3";
import { createInvoiceAsync, updateInvoiceAsync } from "utils/invoice";

interface InvoiceFormikValues {
  id?: string;
  name: string;
}

const getFieldMetadataFromDataType = (
  dataType: CustomDataTypeEnum,
  fieldName: string,
  displayName: string
): InvoiceFieldMetadata => {
  const map = {
    text: {
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: TextFieldString,
    },
    multiline_text: {
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: TextFieldStringMultiline,
    },
    whole_number: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: TextFieldWholeNumber,
    },
    whole_number_unformatted: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: TextFieldWholeNumberUnformatted,
    },
    decimal_number: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: TextFieldTwoDecimal,
    },
    percent_number: {
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .max(1, `${displayName} cannot be greater than 100%.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: PercentField,
    },
    currency: {
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: TextFieldCurrency,
      submitHandler: submitCurrency,
      formatForDisplay: formatCurrency,
    },
    date: {
      initialValue: null,
      _schema: Yup.date()
        .nullable()
        .typeError(`${displayName} must be a date.`),
      component: DatePicker,
      submitHandler: dateSubmitHandler,
      formatForDisplay: formatDateForDisplay,
    },
    boolean: {
      initialValue: false,
      _schema: Yup.boolean().nullable(),
      component: CheckboxField,
    },
  };

  return { fieldName: fieldName, displayName: displayName, ...map[dataType] };
};

interface InvoiceFormProps {
  record?: InvoiceRead;
  changes?: Partial<InvoiceFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  formFields: Record<string, FormFieldRead>;
}

function InvoiceForm({
  record,
  changes = {},
  submitData,
  formLayout,
  formFields,
}: InvoiceFormProps) {
  const InvoiceMetadata: InvoiceObjectMetadataV3 = useMemo(
    () => ({
      ...InvoiceBaseMetadata,
      additional_data: reduce(
        formFields,
        (acc, value) => ({
          ...acc,
          [value.name]: getFieldMetadataFromDataType(
            value.data_type,
            value.name,
            value.display_name
          ),
        }),
        {}
      ),
    }),
    []
  );

  return (
    <InvoiceFormBase
      submitData={submitData}
      formLayout={formLayout}
      changes={changes}
      record={record}
      metadata={InvoiceMetadata}
    />
  );
}

interface InvoiceFormBaseProps {
  record?: InvoiceRead;
  changes?: Partial<InvoiceFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  metadata: InvoiceObjectMetadataV3;
}

function InvoiceFormBase({
  record,
  changes = {},
  submitData,
  formLayout,
  metadata,
}: InvoiceFormBaseProps) {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();

  const initialValues = useMemo(
    () =>
      getInitialValuesV3<InvoiceRead, Partial<InvoiceFormikValues>>(
        metadata,
        record,
        changes,
        usersDisplay,
        user
      ),
    [changes, record, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV3(metadata),
    []
  );

  const onSubmit = useMemo(
    () => getOnSubmitV3(metadata, submitData),
    [submitData]
  );

  const DynamicFieldComponent = useMemo(
    () =>
      ({
        fieldName,
        columnWidth,
      }: {
        fieldName: string;
        columnWidth: number;
      }) => {
        const field = get(metadata, fieldName);
        if (!isFieldMetadataV3(field)) return null;

        const { component: FieldComponent, displayName } = field;

        if (!FieldComponent) return null;

        return (
          <Grid item xs={12} sm={columnWidth}>
            <FieldComponent fieldName={fieldName} displayName={displayName} />
          </Grid>
        );
      },
    []
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        <YupRequiredFieldsProvider validationSchema={validationSchema}>
          <Form>
            <Dialog open={true} scroll={"paper"} maxWidth={"md"}>
              <DialogTitle>{record ? "Edit" : "New"} Invoice</DialogTitle>
              <DialogContent dividers>
                <Stack spacing={2}>
                  {formLayout.layout.map((section, sectionIndex) => (
                    <MainCard
                      title={section.name}
                      key={`section-${sectionIndex}`}
                    >
                      <Grid container spacing={1}>
                        {section.content.map((item, fieldIndex) => (
                          <DynamicFieldComponent
                            columnWidth={item.column_width}
                            fieldName={
                              item.field_type === "default"
                                ? `${item.field_name}`
                                : `additional_data.${item.field_name}`
                            }
                            key={`field-${item.field_name}`}
                          />
                        ))}
                      </Grid>
                    </MainCard>
                  ))}
                </Stack>
              </DialogContent>
              <FormDialogActions />
            </Dialog>
          </Form>
        </YupRequiredFieldsProvider>
      </Formik>
    </LocalizationProvider>
  );
}

interface InvoiceEditFormProps {
  id: string;
  changes?: Partial<InvoiceFormikValues>;
  handleClose: () => void;
}

const InvoiceEditForm = ({
  id,
  changes,
  handleClose,
}: InvoiceEditFormProps) => {
  const { data: formLayout } = useFormLayoutQuery(CustomFormEntityEnum.invoice);
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.invoice
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: Invoice) => {
      await updateInvoiceAsync(id, record);
      await queryClient.invalidateQueries(getInvoiceQueryKey(id));
      // TODO: Clear list view cache
      handleClose();
    },
    [handleClose, id, queryClient]
  );

  const { data } = useInvoiceQuery(id);

  return !!formLayout && !!formFields && !!data ? (
    <InvoiceForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
      record={data}
    />
  ) : null;
};

interface InvoiceCreateFormProps {
  changes?: Partial<InvoiceFormikValues>;
  handleClose: () => void;
}

const InvoiceCreateForm = ({
  changes,
  handleClose,
}: InvoiceCreateFormProps) => {
  const { data: formLayout } = useFormLayoutQuery(CustomFormEntityEnum.invoice);
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.invoice
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: Invoice) => {
      await createInvoiceAsync(record);
      // TODO: Clear list view cache
      handleClose();
    },
    [handleClose]
  );

  return !!formLayout && !!formFields ? (
    <InvoiceForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
    />
  ) : null;
};

const InvoiceFormPage = ({
  id,
  handleClose,
}: {
  id?: string;
  handleClose: () => void;
}) => {
  return id ? (
    <InvoiceEditForm id={id} handleClose={handleClose} />
  ) : (
    <InvoiceCreateForm handleClose={handleClose} />
  );
};

export default InvoiceFormPage;
