import { Button, ButtonProps } from "@mui/material";

export function SimpleButton({ ...rest }: ButtonProps) {
  return (
    <Button
      variant="outlined"
      color="inherit"
      fullWidth
      sx={{
        justifyContent: "flex-start",
        bgcolor: "background.paper",
        borderColor: "divider",
        padding: "10px 16px",
      }}
      {...rest}
    />
  );
}
