import { Box } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";

// ==============================|| DRAWER HEADER - STYLED ||============================== //

interface Props {
  theme: Theme;
}

const DrawerHeaderStyled = styled(Box)(({ theme }: Props) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "48px 32px 16px",
}));

export default DrawerHeaderStyled;
