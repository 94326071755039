import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { drawerWidth } from "config";

import DrawerContent from "./DrawerContent";
import DrawerHeader from "./DrawerHeader";
import DrawerStyled from "./DrawerStyled";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const MainDrawer = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  const transition = theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  });

  return (
    <Box
      component="nav"
      aria-label="mailbox folders"
      style={
        !matchDownMd && open
          ? { width: `${drawerWidth}px`, transition }
          : { width: 0, transition }
      }
    >
      <DrawerStyled
        variant={!matchDownMd ? "permanent" : "temporary"}
        open={open}
        onClose={handleDrawerToggle}
      >
        <DrawerHeader />
        <DrawerContent />
      </DrawerStyled>
    </Box>
  );
};

export default MainDrawer;
