import { DeleteFilled } from "@ant-design/icons";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik } from "formik";
import { get, reduce, startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { formatDateForDisplay } from "components/form/standard/utils/formatting";
import {
  deriveYupSchemaFromMetadataV3,
  getInitialValuesV3,
  getOnSubmitV3,
} from "components/form/standard/utils/metadataV3";
import MainCard from "components/MainCard";
import { SimpleButton } from "components/SimpleButton";
import {
  componentMap,
  PropertyBaseMetadata,
} from "constants/objectMetadata/propertyMetadata";
import { usePushForm, usePopForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import {
  useIsFieldRequired,
  YupRequiredFieldsProvider,
} from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { CloseButton } from "pages/deal/components/CloseButton";
import { dateSubmitHandler, submitCurrency } from "pages/deal/utils/deal_form";
import { formatCurrency } from "pages/deal/utils/reporting";
import { useFormFieldDictQuery } from "queries/useFormField";
import { useFormLayoutQuery } from "queries/useFormLayout";
import {
  getPropertyRecordQueryKey,
  usePropertyRecordQuery,
} from "queries/usePropertyRecord";
import { RecordActionResponse } from "types/api/deal/api";
import {
  CustomDataTypeEnum,
  CustomFormEntityEnum,
  FormFieldRead,
} from "types/api/deal/dynamic_form/form_field";
import { FormLayoutRead } from "types/api/deal/dynamic_form/form_layout";
import { PropertyCreate, PropertyRead } from "types/api/deal/property";
import {
  isFieldMetadataV3,
  PropertyFieldMetadata,
  PropertyObjectMetadataV3,
} from "types/standardFormV3";
import {
  createPropertyAsync,
  updatePropertyAsync,
  deletePropertyAsync,
} from "utils/property";

import { LightTooltip } from "../basic/creone_field";

interface PropertyFormikValues {
  id?: string;
  name: string;
}

const getFieldMetadataFromDataType = (
  dataType: CustomDataTypeEnum,
  fieldName: string,
  displayName: string
): PropertyFieldMetadata => {
  const map = {
    text: {
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: "TextFieldString",
    },
    multiline_text: {
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: "TextFieldStringMultiline",
    },
    whole_number: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: "TextFieldWholeNumber",
    },
    whole_number_unformatted: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: "TextFieldWholeNumberUnformatted",
    },
    decimal_number: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: "TextFieldDecimalNumber",
    },
    percent_number: {
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .max(1, `${displayName} cannot be greater than 100%.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: "TextFieldPercent",
    },
    currency: {
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: "TextFieldCurrency",
      submitHandler: submitCurrency,
      formatForDisplay: formatCurrency,
    },
    date: {
      initialValue: null,
      _schema: Yup.date()
        .nullable()
        .typeError(`${displayName} must be a date.`),
      component: "DatePicker",
      submitHandler: dateSubmitHandler,
      formatForDisplay: formatDateForDisplay,
    },
    boolean: {
      initialValue: false,
      _schema: Yup.boolean().nullable(),
      component: "CheckboxField",
    },
  };

  return { fieldName: fieldName, displayName: displayName, ...map[dataType] };
};

interface PropertyFormProps {
  record?: PropertyRead;
  changes?: Partial<PropertyFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  formFields: Record<string, FormFieldRead>;
}

function PropertyForm({
  record,
  changes = {},
  submitData,
  formLayout,
  formFields,
}: PropertyFormProps) {
  const PropertyMetadata: PropertyObjectMetadataV3 = useMemo(
    () => ({
      ...PropertyBaseMetadata,
      additional_data: reduce(
        formFields,
        (acc, value) => ({
          ...acc,
          [value.name]: getFieldMetadataFromDataType(
            value.data_type,
            value.name,
            value.display_name
          ),
        }),
        {}
      ),
    }),
    [formFields]
  );

  return (
    <PropertyFormBase
      submitData={submitData}
      formLayout={formLayout}
      changes={changes}
      record={record}
      metadata={PropertyMetadata}
    />
  );
}

interface PropertyFormBaseProps {
  record?: PropertyRead;
  changes?: Partial<PropertyFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  metadata: PropertyObjectMetadataV3;
}

function PropertyFormBase({
  record,
  changes = {},
  submitData,
  formLayout,
  metadata,
}: PropertyFormBaseProps) {
  const queryClient = useQueryClient();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();
  const popForm = usePopForm();

  const initialValues = useMemo(
    () =>
      getInitialValuesV3<PropertyRead, Partial<PropertyFormikValues>>(
        metadata,
        record,
        changes,
        usersDisplay,
        user
      ),
    [changes, metadata, record, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV3(metadata),
    [metadata]
  );

  const onSubmit = useMemo(
    () => getOnSubmitV3(metadata, submitData),
    [metadata, submitData]
  );

  const DynamicFieldComponent = useMemo(
    () =>
      ({
        fieldName,
        columnWidth,
      }: {
        fieldName: string;
        columnWidth: number;
      }) => {
        const field = get(metadata, fieldName);
        const required = useIsFieldRequired(fieldName);
        if (!isFieldMetadataV3(field)) return null;

        const { component, displayName } = field;

        const FieldComponent =
          componentMap[component as keyof typeof componentMap];

        if (!FieldComponent) return null;

        return (
          <Grid item xs={12} sm={columnWidth}>
            <FieldComponent
              fieldName={fieldName}
              displayName={displayName}
              required={required}
            />
          </Grid>
        );
      },
    [metadata]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ handleSubmit }) => (
          <YupRequiredFieldsProvider validationSchema={validationSchema}>
            <Form>
              <Dialog open={true} scroll={"paper"} maxWidth={"md"}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "20px",
                  }}
                >
                  <DialogTitle>{record ? "Edit" : "New"} Property </DialogTitle>
                  <CloseButton onClose={popForm} />
                </div>
                <DialogContent dividers>
                  <Stack spacing={2}>
                    {formLayout.layout.map((section, sectionIndex) => (
                      <MainCard
                        title={section.name}
                        key={`section-${sectionIndex}`}
                      >
                        <Grid container spacing={2}>
                          {section.content.map((item) => {
                            return (
                              <DynamicFieldComponent
                                columnWidth={item.column_width}
                                fieldName={
                                  item.field_type === "default"
                                    ? `${item.field_name}`
                                    : `additional_data.${item.field_name}`
                                }
                                key={`field-${item.field_name}`}
                              />
                            );
                          })}
                        </Grid>
                      </MainCard>
                    ))}
                  </Stack>
                </DialogContent>
                <Stack
                  direction="row-reverse"
                  justifyContent="space-between"
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    alignItems="center"
                  >
                    <Button color="secondary" onClick={popForm} size={"large"}>
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </Stack>

                  {record?.id ? (
                    <LightTooltip title="Delete this property.">
                      <IconButton
                        onClick={() => setDeleteModalOpen(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </LightTooltip>
                  ) : null}
                </Stack>
              </Dialog>
              <ConfirmDeleteDialog
                entity="Property"
                isOpen={deleteModalOpen}
                handleDeleteClose={() => setDeleteModalOpen(false)}
                handleDeleteField={() => {
                  record &&
                    deletePropertyAsync(record?.id).then(() => {
                      queryClient.invalidateQueries(["property_table"]);
                      setDeleteModalOpen(false);
                      popForm();
                    });
                }}
              />
            </Form>
          </YupRequiredFieldsProvider>
        )}
      </Formik>
    </LocalizationProvider>
  );
}

const ConfirmDeleteDialog = ({
  entity,
  isOpen,
  handleDeleteClose,
  handleDeleteField,
}: {
  entity: string;
  isOpen: boolean;
  handleDeleteClose: () => void;
  handleDeleteField: () => void;
}) => (
  <Dialog
    open={isOpen}
    onClose={handleDeleteClose}
    maxWidth="xs"
    sx={{ "& .MuiDialog-paper": { padding: "16px" } }}
  >
    <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
      Delete {startCase(entity)}
    </DialogTitle>
    <DialogContent>
      <Typography sx={{ textAlign: "center", marginBottom: "16px" }}>
        Are you sure you want to delete this {entity.toLowerCase()}?
      </Typography>
    </DialogContent>
    <Stack spacing={2} direction="row" justifyContent="center">
      <SimpleButton
        variant="contained"
        color="error"
        onClick={() => {
          handleDeleteField();
        }}
        sx={{ minWidth: "100px" }}
      >
        Delete
      </SimpleButton>
      <SimpleButton
        variant="outlined"
        onClick={handleDeleteClose}
        sx={{ minWidth: "100px" }}
      >
        Cancel
      </SimpleButton>
    </Stack>
  </Dialog>
);

interface PropertyEditFormProps {
  id: string;
  changes?: Partial<PropertyFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const PropertyEditForm = ({
  id,
  changes,
  handleClose,
  successCallback,
}: PropertyEditFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: PropertyCreate) => {
      const responseObj = await updatePropertyAsync(id, record, pushForm);
      await queryClient.invalidateQueries(getPropertyRecordQueryKey(id));
      await queryClient.invalidateQueries(["property_table"]);
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, id, pushForm, queryClient]
  );

  const { data } = usePropertyRecordQuery(id);

  return !!formLayout && !!formFields && !!data ? (
    <PropertyForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
      record={data}
    />
  ) : null;
};

interface PropertyCreateFormProps {
  changes?: Partial<PropertyFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const PropertyCreateForm = ({
  changes,
  handleClose,
  successCallback,
}: PropertyCreateFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: PropertyCreate) => {
      const responseObj = await createPropertyAsync(record, pushForm);
      await queryClient.invalidateQueries(["property_table"]);
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, pushForm, queryClient]
  );

  return !!formLayout && !!formFields ? (
    <PropertyForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
    />
  ) : null;
};

const PropertyFormPage = ({
  id,
  handleClose,
  successCallback,
}: {
  id?: string;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}) => {
  return id ? (
    <PropertyEditForm
      id={id}
      handleClose={handleClose}
      successCallback={successCallback}
    />
  ) : (
    <PropertyCreateForm
      handleClose={handleClose}
      successCallback={successCallback}
    />
  );
};

export default PropertyFormPage;
