import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import navLogo from "assets/images/nav-logo.png";
import Logo from "components/logo";

import DrawerHeaderStyled from "./DrawerHeaderStyled";

// ==============================|| DRAWER HEADER ||============================== //

const LogoTextImg = styled("img")(() => ({
  paddingLeft: "10px",
  height: "24px",
}));

const DrawerHeader = () => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme}>
      <Logo isIcon sx={{ width: "auto", height: 40 }} />{" "}
      <LogoTextImg alt="OneSource" src={navLogo} />
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
