import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  forwardRef,
  useEffect,
  ForwardRefExoticComponent,
  RefAttributes,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { activeItem } from "store/reducers/menu";
import { LinkTarget, NavItemType } from "types/menu";
import { RootStateProps } from "types/root";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { item, level } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { openItem, drawerOpen } = menu;

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: {
    component:
      | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
      | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url!} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon style={{ fontSize: "1.25rem" }} /> : false;

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes("product-details")) {
      if (item.url && item.url.includes("product-details")) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if ((item.url && pathname.startsWith(item.url)) || pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark" ? "text.primary" : "primary.main";

  return (
    <ListItemButton
      tabIndex={!drawerOpen ? -1 : 0}
      ref={ref}
      {...listItemProps}
      onClick={item.onClick}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: `${level * 20}px`,
        py: 1.25,
        mx: 1.5,
        mb: 0.5,
        borderRadius: "8px",

        "&:hover": {
          bgcolor:
            theme.palette.mode === "dark" ? "divider" : "primary.lighter",
        },
        "&.Mui-selected": {
          bgcolor:
            theme.palette.mode === "dark" ? "divider" : "primary.lighter",
          color: iconSelectedColor,
          "&:hover": {
            color: iconSelectedColor,
            bgcolor:
              theme.palette.mode === "dark" ? "divider" : "primary.lighter",
          },
        },
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : iconSelectedColor,
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography
            variant="h6"
            sx={{ color: isSelected ? iconSelectedColor : textColor }}
          >
            {item.title}
          </Typography>
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          sx={{
            ...item.chip.style,
            ...(isSelected && { backgroundColor: item.chip.style?.color }),
            ...(isSelected && {
              color: item.chip.style?.backgroundColor,
            }),
          }}
        />
      )}
    </ListItemButton>
  );
});

export default NavItem;
