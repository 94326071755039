import { ButtonBase } from "@mui/material";
import { SxProps } from "@mui/system";
import { useMemo } from "react";
import { Link, To } from "react-router-dom";

import useAuth from "hooks/useAuth";
import { getDefaultRoute } from "utils/getDefaultRoute";

import LogoIcon from "./LogoIcon";
import Logo from "./LogoMain";

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ isIcon, sx, to }: Props) => {
  const { user } = useAuth();
  const defaultPath = useMemo(() => getDefaultRoute(user), [user]);

  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={!to ? defaultPath : to}
      sx={sx}
    >
      {isIcon ? <LogoIcon /> : <Logo />}
    </ButtonBase>
  );
};

export default LogoSection;
