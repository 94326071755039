import Drawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";

import { drawerWidth } from "config";

const drawerMixin = (theme: Theme): CSSObject => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    background: "#2D3343",
  },
  // nav item text
  "& h6": {
    color: "white",
    fontWeight: 500,
  },
  "& .MuiTypography-subtitle2": {
    fontWeight: 600,
  },
  // nav items (hovered)
  "& .MuiListItemButton-root:hover": {
    background: `rgba(255,255,255,0.15) !important`,
  },
  // nav item (selected) anchor elements
  "& a.MuiListItemButton-root.Mui-selected": {
    backgroundColor: `rgba(255,255,255,0.15) !important`,
    borderRight: "none",
  },
  // icons (all)
  "& svg.MuiSvgIcon-root, .anticon": {
    color: `white !important`,
  },
  "& div.MuiListItemIcon-root": {
    background: "transparent",
  },
  // icons (forexpand/collase)
  "& .anticon-up, & .anticon-down": {
    color: "rgba(255,255,255,1) !important",
  },
});

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: theme.palette.success.main,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export const closedMixin = (theme: Theme): CSSObject => ({
  width: 0,
  backgroundColor: theme.palette.success.main,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const DrawerStyled = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  width: drawerWidth,
  ...drawerMixin(theme),
  [theme.breakpoints.up("lg")]: {
    ...(open && {
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  },
}));

export default DrawerStyled;
