import { BorderOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { NavItemType } from "types/menu";
import { RootStateProps } from "types/root";

import NavItem from "./NavItem";

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

interface Props {
  menu: NavItemType;
  level: number;
}

const NavCollapse = ({ menu, level }: Props) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);

  const menuState = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menuState;

  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
  >(null);

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined
  ) => {
    setAnchorEl(null);
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    const childrens = menu.children ? menu.children : [];
    childrens.forEach((item) => {
      if (pathname && pathname.includes("product-details")) {
        if (item.url && item.url.includes("product-details")) {
          setOpen(true);
        }
      }

      if (item.url === pathname) {
        setOpen(true);
        setSelected(menu.id);
      }
    });
  }, [pathname, menu]);

  const openMini = Boolean(anchorEl);

  const navCollapse = menu.children?.map((item) => {
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Collapse or Item
          </Typography>
        );
    }
  });

  const borderIcon =
    level === 1 ? <BorderOutlined style={{ fontSize: "1rem" }} /> : false;
  const Icon = menu.icon!;
  const menuIcon = menu.icon ? (
    <Icon style={{ fontSize: "1.25rem" }} />
  ) : (
    borderIcon
  );
  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main;

  return (
    <>
      <ListItemButton
        tabIndex={!drawerOpen ? -1 : 0}
        disableRipple
        selected={selected === menu.id}
        onClick={handleClick}
        sx={{
          pl: `20px`,
          py: 1.25,
          mx: 1.5,
          mb: 0.5,
          borderRadius: "8px",

          "&:hover": {
            bgcolor:
              theme.palette.mode === "dark" ? "divider" : "primary.lighter",
          },
          "&.Mui-selected": {
            bgcolor: "transparent",
            color: iconSelectedColor,
            "&:hover": {
              color: iconSelectedColor,
              bgcolor:
                theme.palette.mode === "dark" ? "divider" : "transparent",
            },
          },
        }}
      >
        {menuIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: "primary.main",
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}

        <ListItemText
          primary={
            <Typography
              variant="h6"
              color={selected === menu.id ? "primary" : textColor}
            >
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" color="secondary">
                {menu.caption}
              </Typography>
            )
          }
        />

        {openMini || open ? (
          <UpOutlined
            style={{
              fontSize: "0.625rem",
              marginLeft: 1,
              color: theme.palette.primary.main,
            }}
          />
        ) : (
          <DownOutlined style={{ fontSize: "0.625rem", marginLeft: 1 }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ pl: 0 }}>{navCollapse}</List>
      </Collapse>
    </>
  );
};

export default NavCollapse;
